/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';

const StyleModal = styled.div`
  a {
    color: #888888;
  }
  hr {
    width: 10%;
    color: #340dca;
    height: 4px;
    opacity: 1;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding: 0 4rem;
  }
  h2,
  h3 {
    font-weight: bold;
  }
  .attention {
    border: 1px solid #340dca;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }
`;

function TermsModal() {
  return (
    <>
      <StyleModal className="viewTerms">
        <div
          className="modal fade"
          id="termsModal"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="termsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <h3
                  className="modal-title text-uppercase mb-3"
                  id="termsModalLabel"
                >
                  TERMS OF SERVICE (TOS)
                </h3>
                <h4 className="text-uppercase">
                  I. Introduction and Eligibility
                </h4>
                <hr className="my-4" />
                <p>
                  These Terms of Use (“Terms”) constitute a binding agreement
                  between you and Profusion Media Limited and its affiliates and
                  subsidiaries (“Profusion Media,” “we,” “us”). Please read
                  these Terms carefully before using the Profusion Media Service
                  (defined below). “You” and “Users” shall mean all visitors to
                  the Profusion Media Service. These Terms include Profusion
                  Media’s Privacy and Cookies Policy (“Privacy Policy”), which
                  is incorporated by reference into these Terms. We have
                  included several annotations in boxes to help explain the
                  legal jargon and highlight key sections. These annotations are
                  for convenience only and have no legal or contractual effect.
                  Your use of the Profusion Media Service signifies your
                  agreement to be bound by these Terms each time you access the
                  Profusion Media Service. If you do not agree to any of these
                  Terms, do not use the Profusion Media Service.
                </p>
                <p className="attention">
                  This agreement is a legally binding contract. It may change as
                  our service changes, and you agree you will review it and any
                  updates regularly.
                </p>
                <p>
                  <span className="fw-bold">Revisions to Terms.</span>
                  &nbsp;We reserve the right, in our sole discretion, to revise
                  these Terms at any time, in whole or in part, by posting an
                  updated version. Changes to these Terms will be effective when
                  posted. You agree to visit this page periodically to review
                  the most current Terms and your continued use of the Profusion
                  Media Service, or any part thereof after any changes to these
                  Terms are posted constitutes your binding acceptance of these
                  Terms. If you object to any changes to these Terms, your sole
                  recourse will be to stop using the Profusion Media Service.
                </p>
                <p className="attention">
                  If you are not a resident of the United Kingdom, by using the
                  Profusion Media Service and submitting personal information to
                  us, you consent to the transfer of your information to the
                  United Kingdom
                </p>
                <p>
                  <span className="fw-bold">International Users.</span>
                  &nbsp;The Profusion Media Service is operated from the United
                  Kingdom but may be accessed in other parts of the world. If
                  you are located outside of the United Kingdom and you access
                  or use the Profusion Media Service, you acknowledge,
                  understand, and agree that you are doing so on your own
                  initiative and at your own risk and that it is your
                  responsibility (and not ours) to make sure that your use of
                  the Profusion Media Service complies with all applicable local
                  laws in the jurisdiction from where you access or use the
                  Profusion Media Service. If you are located in the United
                  Kingdom, for purposes of the Data Protection Act 1998,
                  Profusion Media Limited is the data controller with respect to
                  any information collected by it in the United Kingdom and or
                  transferred to it from outside the United Kingdom. By
                  submitting any personally identifiable information to us
                  through the Profusion Media Service, you consent to the
                  transfer, processing, and storage of your information in the
                  United Kingdom in accordance with our Privacy Policy.
                </p>
                <h4 className="text-uppercase">
                  II. The Profusion Media Service
                </h4>
                <p>
                  The “Profusion Media Service” means the website located
                  at&nbsp;
                  <a href="https://sisensetraining.profusion.com/">
                    sisensetraining.profusion.com
                  </a>
                  &nbsp;and any associated software, applications, and Internet
                  services under Profusion Media’s control, whether partial or
                  otherwise, used in connection with providing the services
                  provided by Profusion Media. The Profusion Media Service
                  provides an online platform to enable you to view information
                  online and through other connected devices about classes,
                  courses, workshops, and other education programs and events
                  offered by Profusion Media (collectively, “Courses”). These
                  Courses will be made available for a fee and in some cases may
                  be offered free of charge, at Profusion Media’s sole
                  discretion. Together with our members, thought leaders, and
                  practitioners (collectively “Instructors”), Profusion Media
                  offers a robust curriculum focused on Data Science, Data
                  Engineering and Business Intelligence solutions and how they
                  can be used and applied to help you make informed business
                  decisions and enhance company performance.
                </p>
                <p>
                  <span className="fw-bold">Sign Up for a Course.</span>
                  &nbsp;You may sign up for a Course using the Profusion Media
                  Service. We cannot promise the availability of the Course.
                </p>
                <p className="attention">
                  Our cancellation and no-show policies are available here.
                </p>
                <p>
                  <span className="fw-bold">
                    Cancellation and No Show Policies.
                  </span>
                  &nbsp;We determine the rescheduling and cancellation policies
                  and apply them at our discretion. You should contact us
                  directly, not Instructors, for any rescheduling or
                  cancellation questions. The Profusion Media Cancellation
                  Policy is available here and is incorporated into these Terms.
                </p>
                <p className="attention">
                  Please exercise caution, good sense, and sound judgment when
                  providing information to other users.
                </p>
                <p>
                  <span className="fw-bold">Messaging and chat rooms.</span>
                  &nbsp;You may be able to engage in online chat sessions with
                  other users of the Profusion Media Service, including
                  Instructors. You should exercise caution, good sense, and
                  sound judgment when submitting messages or information to be
                  posted in a chat room. Think – and read twice – before you
                  post anything. Once something is posted online it may live in
                  perpetuity, and messages posted today could be damaging to
                  your credibility or reputation in the future. You are
                  responsible for any comments or materials you post in a chat
                  room or on a blog, and you assume all liability arising out of
                  a post. We discourage the posting of sensitive or personal
                  information in comments or posts and we are not responsible
                  for the use of your personal information that you publicly
                  post by any third parties. Profusion Media makes no
                  representations and warranties with respect to the
                  confidentiality of any posts you make through the Profusion
                  Media Service.
                </p>
                <p>
                  <span className="fw-bold">
                    Information You Provide to Instructors.
                  </span>
                  &nbsp;Your Instructors may ask you for information, including
                  personal information, such as your email address, to help
                  facilitate the provision of the Course. Instructors are
                  required to use this information only to communicate about
                  Course materials in a professional manner. However, you agree
                  that we are not responsible for any use of this information by
                  an Instructor for other purposes.
                </p>
                <p className="attention">
                  We use third-party services to help us provide the Profusion
                  Media Service, but such use does not indicate that we endorse
                  them or are responsible or liable for their actions.
                </p>
                <p>
                  <span className="fw-bold">Third-Party Services.</span>
                  &nbsp;The Profusion Media Service may link to third-party
                  websites to facilitate its provision of services to you. If
                  you use these links, you will leave the Profusion Media
                  Service. Some of these third-party websites may use Profusion
                  Media Content (defined below) under license from Profusion
                  Media. Profusion Media is not responsible for these
                  third-party websites, whether or not Profusion Media is
                  affiliated with such third-party websites. Through our use, we
                  do not endorse the organisations sponsoring such third-party
                  websites or their products or services. You agree that
                  Profusion Media is not responsible or liable for any loss or
                  damage of any sort incurred as a result of any such dealings
                  you may have on or through a third-party website or as a
                  result of the presence of any third-party advertising on the
                  Profusion Media Service.
                </p>
                <h4 className="text-uppercase">III. Financial Matters</h4>
                <p className="attention">
                  You may have to pay a fee to attend a course through the
                  Profusion Media Service.
                </p>
                <p>
                  <span className="fw-bold">Courses.</span>
                  &nbsp;Profusion media may charge you fees to attend a Course.
                  The amount of any fees may be revised from time to time and
                  vary from region to region, and topic to topic.
                </p>
                <p className="attention">
                  When you sign up for a Course that requires a payment, you
                  agree to pay it. We use third-party service providers, which
                  offer various payment methods. Such payment methods could
                  change.
                </p>
                <p>
                  <span className="fw-bold">
                    You Agree To Pay Us For Your Purchases
                  </span>
                  &nbsp;You agree to pay for all products and services that you
                  purchase through the Profusion Media Service, and you agree
                  that we may charge your selected payment method, either
                  directly or through the services of a third-party payment
                  processor, for any such payments. Only those payment methods
                  accepted by our third-party payment processor can be used to
                  purchase products, goods, or services through the Profusion
                  Media Service.
                </p>
                <p className="attention">
                  You are responsible for all fees charged to make payments
                  using payment processors through the Profusion Media Service.
                </p>
                <p>
                  <span className="fw-bold">
                    Third-Party Payment Processors.
                  </span>
                  &nbsp;Profusion Media currently uses secure third-party
                  payment processors for electronic commerce. Our third-party
                  payment processors accept payments through methods detailed on
                  the applicable payment screen, which may include various
                  credit cards and / or PayPal. Information that you supply to
                  our payment processors is not stored by us or within our
                  control and is subject to each of our third-party payment
                  processors’ own privacy policies and terms and conditions.
                  Third-party payment processors may charge a fee to process
                  payments and Profusion Media is not responsible for any fees
                  charged by them. Profusion Media disclaims all liability with
                  regard to any fees or problems you have with third-party
                  payment processors
                </p>
                <p>
                  <span className="fw-bold">
                    Availability of Certain Forms of Payment.
                  </span>
                  &nbsp;Profusion Media makes no representations and warranties
                  about the continued availability of any particular form of
                  payment method made available for use with the Profusion Media
                  Service.
                </p>
                <p>
                  <span className="fw-bold">Pre-Authorisation.</span>
                  &nbsp;When you provide a credit card number to us to activate
                  and/or pay for any fees related to our services we, through
                  our third-party payment processor, may seek pre-authorisation
                  of your credit card account prior to a purchase to verify the
                  credit card is valid and/or has the necessary funds or credit
                  available to cover your purchase. These pre-authorisations
                  will reduce your available balance by the authorization amount
                  until it is released or reconciled with the actual charge.
                  Please contact your card issuer if you have additional
                  questions regarding when an authorisation amount will be
                  removed from your statement.
                </p>
                <p>
                  <span className="fw-bold">Timing of Charges.</span>
                  &nbsp;Charges occur within a reasonable time of the
                  transaction or shortly thereafter, and multiple charges during
                  the same period may be aggregated together.
                </p>
                <p className="attention">
                  You may send us an email to dispute charges to your account
                  within 30 days of the charge.
                </p>
                <p>
                  <span className="fw-bold">Disputed Charges.</span>
                  &nbsp;You agree to submit any disputes regarding any charge to
                  your account in writing to Profusion Media within thirty (30)
                  days of such charge, or your dispute is waived and such charge
                  will be final and not subject to challenge. You may dispute a
                  charge by sending an email to us at&nbsp;
                  <a href="mailto: sisense-bookings@profusion.com">
                    sisense-bookings@profusion.com
                  </a>
                </p>
                <p className="attention">
                  Profusion Media may provide refunds at its discretion. Our
                  refund policy is available here.
                </p>
                <p>
                  <span className="fw-bold">Refunds.</span>
                  &nbsp;We reserve the right, but are not obligated, to refund
                  fees paid to us. Our policy for providing refunds, if ever,
                  for any purchases made by you, is set forth in the Profusion
                  Media Refund Policy available here and is incorporated into
                  these Terms. Profusion Media may modify its refund policy at
                  any time with or without specific notice to you; provided,
                  however, that the refund policy in effect at the time of any
                  transaction shall apply to such purchase despite any
                  subsequent change in such policy. Please contact
                  sisense-bookings@profusion.com to request a refund.
                </p>
                <p className="attention">
                  Profusion Media will not collect or pay your taxes.
                </p>
                <p>
                  <span className="fw-bold">Taxes.</span>
                  &nbsp;“Taxes” include sales, use, value added, or transaction
                  taxes and other government-imposed fees and charges. You are
                  responsible for determining and paying the appropriate taxes
                  resulting from a transaction occurring through the Profusion
                  Media Service. Profusion Media is not responsible for
                  collecting, reporting, paying, or remitting to you any such
                  taxes, unless required by law.
                </p>
                <p>
                  <span className="fw-bold">Currency.</span>
                  &nbsp;We display prices in GBP and our third-party payment
                  provider will process the transaction and your local bank will
                  charge you in your local currency, plus any relevant bank
                  charges for international transactions.
                </p>
                <h4 className="text-uppercase">
                  IV. Account Creation and Use by an Agent
                </h4>
                <p>
                  You agree that if you create an account and use the Profusion
                  Media Service on behalf of a business entity (e.g.,
                  corporation), you have the necessary legal authority and
                  capacity to do so as an authorised agent of the business
                  entity. You agree that you have the authority to bind the
                  business entity in legal agreements and contracts.
                </p>
                <h4 className="text-uppercase">V. Your Account</h4>
                <p className="attention">
                  If you create an account, you are responsible for the
                  confidentiality of
                  {' '}
                  <strong>your log-in credentials</strong>
                  {' '}
                  and for keeping your information accurate.
                  <br />
                  <br />
                  You are responsible for any activity resulting from the use of
                  {' '}
                  <strong>your log-in credentials</strong>
                  {' '}
                   on the Profusion Media Service.
                </p>
                <p>
                  To use some parts of the Profusion Media Service, you must
                  create an account, which will mean you need to provide us with
                  your name, email address and billing address, as well as your
                  debit/credit card information for our chosen payment provider.
                  You represent and warrant that the information you provide to
                  Profusion Media upon registration and at all other times will
                  be true, accurate, current, and complete. You also represent
                  and warrant that you will ensure that this information is kept
                  accurate and up-to-date at all times.
                </p>
                <p>
                  Your Log-In Credentials. You are responsible for maintaining
                  the confidentiality of your log-in credentials and are fully
                  responsible for all activities that occur through the use of
                  your credentials. You agree to notify us immediately if you
                  believe the confidentiality of your log-in credentials has
                  been compromised or if you suspect unauthorised use of your
                  account. You agree that we will not be liable for any loss or
                  damage arising from unauthorised use of your credentials.
                </p>
                <h4 className="text-uppercase">VI. Communications</h4>
                <p className="attention">
                  Profusion Media may communicate with you by email under
                  certain circumstances, or by posting a notice on the Profusion
                  Media Service/website. You may request that we provide notice
                  of security breaches in writing.
                </p>
                <p>
                  If you have registered to use the Profusion Media Service or
                  are registered for a Course, you agree to receive email from
                  us at the email address you provided to us for customer
                  service-related purposes, regardless of marketing
                  communications preferences.
                </p>
                <p>
                  <span className="fw-bold">Electronic Notices.</span>
                  &nbsp;By using the Profusion Media Service or providing any
                  personal information to us, you agree that we may communicate
                  with you electronically regarding security, privacy, and
                  administrative issues relating to your use of the Profusion
                  Media Service. If we learn of a security breach relating to
                  your personal information that is required to be reported
                  pursuant to applicable security breach notification laws, we
                  may attempt to notify you electronically by posting a notice
                  on the Profusion Media Service or by sending an email to you.
                  You may have a legal right to receive this notice in writing.
                  To receive free written notice of a security breach (or to
                  withdraw your consent from receiving electronic notice),
                  please write to us at&nbsp;
                  <a href="mailto: dpo@profusion.com">dpo@profusion.com</a>
                </p>
                <h4 className="text-uppercase">
                  VII. Profusion Media’s Content Ownership and Use
                </h4>
                <p>
                  Profusion Media owns all of the content we create, but you may
                  use it, subject to these Terms, while you use the Profusion
                  Media Service. You cannot use our logo without our written
                  permission.
                </p>
                <p>
                  The contents of the Profusion Media Service include: designs,
                  text, graphics, images, video, information, logos, button
                  icons, software, audio files, computer code, and other
                  Profusion Media content (collectively, “Profusion Media
                  Content” ). All Profusion Media Content and the compilation
                  (meaning the collection, arrangement, and assembly) of all
                  Profusion Media Content are the property of Profusion Media or
                  its licensors and are protected under copyright, trademark,
                  and other laws.
                </p>
                <p>
                  <span className="fw-bold">License to You.</span>
                  &nbsp;Subject to these Terms, we hereby grant you the limited,
                  revocable, non-exclusive, non-transferable, non-assignable,
                  worldwide license to access and use the Profusuon Media
                  Service and the Profusion Media Content solely for the use of
                  Profusion Media’s services, at our discretion. Any other use
                  is expressly prohibited. This license is revocable at any time
                  without notice and with or without cause. Unauthorised use of
                  the Profusion Media Content may violate copyright, trademark,
                  and applicable communications regulations and statutes and is
                  strictly prohibited. You must preserve all copyright,
                  trademarks, service marks, and other proprietary notices
                  contained in the original Profusion Media Content on any copy
                  you make of the Profusion Media Content in accordance with
                  these Terms.
                </p>
                <p>
                  No Profusion Media Content or other material made available on
                  or through the Profusion Media Service may be copied,
                  modified, reproduced, duplicated, republished, uploaded,
                  posted, transmitted, sold, transferred, publicly displayed,
                  distributed, or used to create derivative works, in any way
                  without written permission of the copyright owner unless such
                  content is specifically made available for and authorised to
                  be downloaded from the Profusion Media Service, in which case
                  you are authorised to download a single copy of such materials
                  for your own use. For example, certain Course materials may be
                  made available as unprotected PDF files that can be downloaded
                  by registered Course participants and/or other users of the
                  Profusion Media Service. Materials not made available for
                  download may not be downloaded or copied without prior written
                  permission. Modification of materials obtained from the
                  Profusion Media Service, including, but not limited to, User
                  Content, for any other purpose, including, without limitation,
                  any commercial purpose, is a violation of the copyrights and
                  other proprietary rights of Profusion Media or its licensors,
                  unless you have obtained express written authorisation to the
                  contrary.
                </p>
                <p>
                  <span className="fw-bold">No Commercial Use.</span>
                  &nbsp;No materials obtained from the Profusion Media Service,
                  even if authorised for download from the Profusion Media
                  Service, may be redistributed, nor may they be used for any
                  commercial purpose, without Profusion Media’s prior written
                  permission. Notwithstanding the prior sentence, the use of
                  Course materials by a registered student of a Course for the
                  development of a business, venture, or idea of which the
                  registered student is actively involved as a founder or
                  employee shall not be deemed a commercial purpose, but no
                  additional reproductions may be made of any such materials,
                  either electronically or in hard copy.
                </p>
                <p>
                  <span className="fw-bold">No Implied Rights.</span>
                  &nbsp;There are no implied licenses granted in these Terms.
                </p>
                <p>
                  <span className="fw-bold">Profusion Media Marks.</span>
                  &nbsp;Profusion Media, the Profusion Media logo, and other
                  Profusion Media logos and product and service names are or may
                  be trademarks of Profusion Media (the “Profusion Media
                  Marks”). Without our prior written permission, and except as
                  solely enabled by any link as provided by us, you agree not to
                  display or use in any manner the Profusion Media Marks.
                </p>
                <h4 className="text-uppercase">
                  VIII. Your User Content and Our License to Use
                </h4>
                <p className="attention">
                  You, or the people who allow you to use their content, own all
                  of the content you post using the Profusion Media Service.
                  However, by posting content to the Profusion Media Service,
                  you are granting us a license to use your content for any
                  purpose, including in our marketing materials. We may also
                  modify your content, in our sole discretion, and without prior
                  approval from you, in order to make it work better with the
                  Profusion Media service. It is very important that you have
                  permission to use other people’s content or they may be able
                  to sue you for violating their legal rights
                </p>
                <p>
                  <span className="fw-bold">Your User Content.</span>
                  &nbsp;The Profusion Media Service may provide you with the
                  ability to create, post, or share content, including messages
                  in chat rooms or comments on blog posts (“Your User Content”).
                  You or a third-party licensor, as appropriate, retain all
                  intellectual property rights to Your User Content. You are
                  responsible for protecting those rights.
                </p>
                <p>
                  <span className="fw-bold">
                    Profusion Media’s Use of Your User Content.
                  </span>
                  &nbsp;By creating, posting, or sharing Your User Content, on
                  or through the Profusion Media Service, and subject to
                  Profusion Media Privacy Policy, and except as otherwise
                  expressly stated, you grant Profusion Media an irrevocable,
                  perpetual, transferrable, unconditional, unrestricted,
                  sublicensable, world-wide, non-exclusive, royalty-free license
                  to copy, use, reproduce, modify, remove, publish, upload,
                  distribute, transmit, publicly display and create derivative
                  works from Your User Content for any purpose without
                  compensation to you, including for the purpose of promoting
                  Profusion Media and our services.
                </p>
                <p>
                  <span className="fw-bold">
                    You Acquire No Ownership of Others’ Content.
                  </span>
                  &nbsp;You understand and agree that you will not obtain,
                  through use of the Profusion Media Service, any right, title,
                  or interest (including intellectual property rights) in
                  content delivered via the Profusion Media Service.
                </p>
                <p>
                  <span className="fw-bold">
                    You Must Have Rights to the Content You Post.
                  </span>
                  &nbsp;You represent and warrant that: (i) you own the content
                  posted by you on or through the Profusion Media Service or
                  otherwise have the right to grant the license set forth in
                  these Terms; (ii) the posting and use of Your User Content on
                  or through the Profusion Media Service does not violate the
                  privacy rights, publicity rights, copyrights, contract rights,
                  intellectual property rights, or any other rights of any
                  person; and (iii) the posting of Your User Content on the
                  Profusion Media Service does not result in a breach of
                  contract between you and a third party. You agree to pay all
                  monies owing to any person as a result of posting Your User
                  Content on the Profusion Media Service. You also acknowledge
                  and agree that Your User Content is non-confidential and
                  non-proprietary.
                </p>
                <p>
                  The Profusion Media Service contains content from users and
                  other Profusion Media licensors. Except as provided within
                  these Terms, you may not copy, modify, translate, publish,
                  broadcast, transmit, distribute, perform, display, or sell any
                  content appearing on or through the Profusion Media Service.
                </p>
                <h4 className="text-uppercase">IX. Copyright Policy</h4>
                <p className="attention">
                  Tell us if you think a user has violated your copyright using
                  the Profusion Media Service, or if you think someone
                  incorrectly reported that you violated his or her copyright.
                </p>
                <p>
                  The Copyright, Designs and Patents Act 1988, is the current UK
                  copyright law provides recourse for copyright owners who
                  believe that material appearing on the Internet infringes
                  their rights under U.K copyright law. If you believe in good
                  faith that materials posted on the Profusion Media Service
                  infringe your copyright, you (or your agent) may send
                  Profusion Media a “Notification of Claimed Infringement”
                  requesting that the material be removed, or access to it
                  blocked. The notice must include the following information:
                </p>
                <ol>
                  <li>
                    A physical or electronic signature of a person authorised to
                    act on behalf of the owner of the works that have been
                    allegedly infringed;
                  </li>
                  <li>
                    Identification of the copyrighted work alleged to have been
                    infringed (or if multiple copyrighted works located on the
                    Profusion Media Service are covered by a single
                    notification, a representative list of such works);
                  </li>
                  <li>
                    Identification of the specific material alleged to be
                    infringing or the subject of infringing activity, and
                    information reasonably sufficient to allow Profusion Media
                    to locate the material on the Profusion Media Service;
                  </li>
                  <li>
                    Your name, address, telephone number, and email address (if
                    available);
                  </li>
                  <li>
                    A statement that you have a good faith belief that use of
                    the material in the manner complained of is not authorised
                    by the copyright owner, its agent, or the law; and
                  </li>
                  <li>
                    A statement that the information in the notification is
                    accurate, and under penalty of perjury, that the complaining
                    party is authorised to act on behalf of the owner of an
                    exclusive right that is allegedly infringed.
                  </li>
                </ol>
                <p>
                  Notices with respect to the Profusion Media Service should be
                  sent to&nbsp;
                  <a href="mailto: legal@profusion.com">legal@profusion.com</a>
                  &nbsp;and copied to&nbsp;
                  <a href="mailto: sisense-bookings@profusion.com">
                    sisense-bookings@profusion.com
                  </a>
                  &nbsp;and we will respond accordingly.
                </p>
                <h4 className="text-uppercase">
                  X. Suggestions and Submissions
                </h4>
                <p className="attention">
                  We appreciate your comments, but if you send us creative
                  ideas, we can use them without compensating you.
                </p>
                <p>
                  We appreciate hearing from our users and welcome your comments
                  regarding the Profusion Media Service. Please be advised,
                  however, that if you send us creative ideas, suggestions,
                  inventions, or materials ( “creative ideas” ), we shall:
                </p>
                <ol>
                  <li>
                    Own, exclusively, all now known or later discovered rights
                    to the creative ideas;
                  </li>
                  <li>
                    Not be subject to any obligation of confidentiality and
                    shall not be liable for any use or disclosure of any
                    creative ideas; and
                  </li>
                  <li>
                    Be entitled to unrestricted use of the creative ideas for
                    any purpose whatsoever, commercial, or otherwise, without
                    compensation to you or any other person.
                  </li>
                </ol>
                <h4 className="text-uppercase">
                  XI. User Content Disclaimers, Limitations, and Prohibitions
                </h4>
                <p className="attention">
                  You are responsible for your actions when using and relying on
                  the Profusion Media Service or content available on the
                  Profusion Media Service.
                </p>
                <p>
                  We do not represent or guarantee the truthfulness, accuracy,
                  or reliability of content, posted by users of the Profusion
                  Media Service. You accept that any reliance on material posted
                  by other users or third-party service providers will be at
                  your own risk. By using the Profusion Media Service you accept
                  the risk that you might be exposed to content that is
                  objectionable or otherwise inappropriate.
                </p>
                <p>
                  You expressly acknowledge and agree that you are solely
                  responsible for Your User Content on the Profusion Media
                  Service. Profusion Media does not endorse, nor is it
                  responsible for, Your User Content on the Profusion Media
                  Service. You assume all risks associated with Your User
                  Content, including anyone’s reliance on its quality, accuracy,
                  or reliability, and you agree that you are solely responsible
                  for any consequences that may arise from the posting of Your
                  User Content through the Profusion Media Service. You may
                  expose yourself to liability if, for example, Your User
                  Content contains material that is false, intentionally
                  misleading, or defamatory; violates third-party rights; or
                  contains material that is unlawful or advocates the violation
                  of any law or regulation.
                </p>
                <p className="attention">
                  Do not misuse the Profusion Media Service or attempt to break
                  it, steal our content, or use the Profusion Media Service to
                  post unlawful or harmful content.
                </p>
                <p>
                  You agree to use the Profusion Media Service only for its
                  intended purpose. You must use the Profusion Media Service in
                  compliance with all privacy, data protection, intellectual
                  property, and other applicable laws. The following uses of the
                  Profusion Media Service are prohibited. You may not:
                </p>
                <ol>
                  <li>
                    Attempt to interfere with, harm, reverse engineer, steal
                    from, or gain unauthorized access to the Profusion Media
                    Service, user accounts, or the technology and equipment
                    supporting the Profusion Media Service;
                  </li>
                  <li>
                    Frame or link to the Profusion Media Service without
                    permission;
                  </li>
                  <li>
                    Use data mining, robots, or other data gathering devices on
                    or through the Profusion Media Service;
                  </li>
                  <li>
                    Post incomplete, false, or misleading information,
                    impersonate another person, or misrepresent your affiliation
                    with a person or entity;
                  </li>
                  <li>
                    Disclose personal information about another person or post
                    any content that is unlawful, pornographic, obscene,
                    defamatory, libellous, threatening, discriminatory,
                    harassing, bullying, vulgar, indecent, profane, hateful,
                    racially, culturally or ethnically offensive, or that
                    encourages criminal conduct or that gives rise, or
                    potentially gives rise to civil or criminal liability or
                    that violates any applicable law;
                  </li>
                  <li>
                    Sell, transfer, or assign any of your rights to use the
                    Profusion Media Service to a third party without our express
                    written consent;
                  </li>
                  <li>
                    Post advertising or marketing links or content, except as
                    specifically allowed by these Terms;
                  </li>
                  <li>
                    Use the Profusion Media Service after your account has been
                    terminated, without our consent;
                  </li>
                  <li>
                    Use the Profusion Media Service in an illegal way or to
                    commit an illegal act in relation to the Profusion Media
                    Service or that otherwise results in fines, penalties, and
                    other liability to Profusion Media or others; or
                  </li>
                  <li>
                    Access the Profusion Media Service from a jurisdiction where
                    it is illegal or unauthorised.
                  </li>
                </ol>
                <h4 className="text-uppercase">
                  XII. Consequences of Violating These Terms
                </h4>
                <p className="attention">
                  If you do not act responsibly and in accordance with these
                  Terms, we may prohibit your use of the Profusion Media
                  Service.
                </p>
                <p>
                  We reserve the right to suspend or terminate your account and
                  prevent access to the Profusion Media Service for any reason,
                  without notice to you, at our discretion. We reserve the right
                  to refuse to provide the Profusion Media Service to you in the
                  future.
                </p>
                <p>
                  Profusion Media may review and remove any of Your User Content
                  at any time for any reason, including for any activity which,
                  in its sole judgement: violates these Terms; violates
                  applicable laws, rules, or regulations; is abusive,
                  disruptive, offensive or illegal; or violates the rights of,
                  or harms or threatens the safety of, users of the Profusion
                  Media Service.
                </p>
                <p>
                  You are responsible for any claims, fees, fines, penalties,
                  and other liability incurred by us or others caused by or
                  arising out of your breach of these Terms and your use of the
                  Profusion Media Service.
                </p>
                <h4 className="text-uppercase">
                  XIII. Profusion Media’s Liability
                </h4>
                <p>
                  We are not liable for the actions of our users when they use
                  the Profusion Media Service. We may also change the Profusion
                  Media Service at any time and are not liable for how this may
                  affect you. We do not guarantee the quality or accuracy of any
                  content you view using the Profusion Media Service or other
                  websites.
                </p>
                <p>
                  <span className="fw-bold">
                    Changes to the Profusion Media Service.
                  </span>
                  &nbsp;We may change, suspend, or discontinue any aspect of the
                  Profusion Media Service at any time, in our sole discretion,
                  including hours of operation or availability of the Profusion
                  Media Service or any feature, without notice or liability.
                </p>
                <p>
                  <span className="fw-bold">User Disputes.</span>
                  &nbsp;We are not responsible for any disputes or disagreements
                  between you and any third party you interact with using the
                  Profusion Media Service, including POP licensing or
                  relationship authentication. You assume all risk associated
                  with dealing with third parties. You agree to resolve disputes
                  directly with the other party. You release Profusion Media of
                  all claims, demands, and damages in disputes among users of
                  the Profusion Media Service. You also agree not to involve us
                  in such disputes. Use caution and common sense when using the
                  Profusion Media Service.
                </p>
                <p>
                  <span className="fw-bold">Content Accuracy.</span>
                  &nbsp;We make no representations about accuracy, reliability,
                  completeness, or timeliness of any contents of the Profusion
                  Media Service. Similarly, we make no representations about
                  accuracy, reliability, completeness, or timeliness of any data
                  from a third-party service provider or the quality or nature
                  of third-party products or services obtained through the
                  Profusion Media Service. Use the Profusion Media Service at
                  your own risk.
                </p>
                <p>
                  <span className="fw-bold">Third-Party Websites.</span>
                  &nbsp;The Profusion Media Service may include links to third
                  party websites and applications. You are responsible for
                  evaluating whether you want to access or use them. We are not
                  responsible for and do not endorse any features, content,
                  advertising, products, or other materials on other websites or
                  applications. You assume all risk and we disclaim all
                  liability arising from your use of them.
                </p>
                <p>
                  We make no promises and disclaim all liability of specific
                  results from the use of the Profusion Media Service.
                </p>
                <h4 className="text-uppercase">A. DISCLAIMER OF WARRANTIES</h4>
                <p>
                  Released Parties Defined. “Released Parties” include Profusion
                  Media and its affiliates, officers, employees, agents, service
                  providers, partners, Instructors, and licensors.
                </p>
                <p>
                  You use the Profusion Media Service at your own risk. We make
                  no warranties or guarantees.
                </p>
                <p className="text-uppercase">
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE
                  PROFUSION MEDIA SERVICE IS AT YOUR SOLE RISK, AND THE
                  PROFUSION MEDIA SERVICE IS PROVIDED ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS AND THE RELEASED PARTIES EXPRESSLY DISCLAIM
                  ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                  INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OR
                  SERVICES OFFERED BY BUSINESSES LISTED ON THE PROFUSION MEDIA
                  SERVICE, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                  PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE RELEASED
                  PARTIES MAKE NO WARRANTY THAT (i) THE PROFUSION MEDIA SERVICE
                  WILL MEET YOUR REQUIREMENTS, (ii) THE PROFUSION MEDIA SERVICE
                  WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii)
                  THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                  PROFUSION MEDIA SERVICE WILL BE ACCURATE OR RELIABLE, (iv)
                  THE QUALITY OF ANY GOODS OR SERVICE AVAILABLE ON THE
                  PROFUSION MEDIA SERVICE WILL MEET YOUR EXPECTATIONSAND, AND
                  (iv) ANY ERRORS IN THE PROFUSION MEDIA SERVICE WILL BE
                  CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE
                  OBTAINED THROUGH THE USE OF THE PROFUSION MEDIA SERVICE IS
                  ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
                  SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
                  MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD
                  OR USE OF ANY SUCH MATERIAL.
                </p>
                <h4 className="text-uppercase">
                  B. LIMITATION OF LIABILITY AND INDEMNIFICATION
                </h4>
                <p>
                  We are not liable for anything that happens to you that
                  somehow may be connected to your use of the Profusion Media
                  Service. You may be responsible for our legal fees and costs
                  arising out of your use of the Profusion Media Service.
                </p>
                <p className="text-uppercase">
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES
                  SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE, UNDER ANY THEORY OF
                  LIABILITY (WHETHER IN CONTRACT, TORT, STATUTORY OR OTHERWISE),
                  FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                  OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES
                  FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                  LOSSES (EVEN IF PROFUSION MEDIA HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR
                  THE INABILITY TO USE THE PROFUSION MEDIA SERVICE; (ii) THE
                  COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
                  FROM ANY GOODS, DATA, INFORMATION OR SERVICES OBTAINED OR
                  MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH, FROM,
                  OR AS A RESULT OF THE PROFUSION MEDIA SERVICE; (iii)
                  UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
                  DATA; (iv) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON
                  THE PROFUSION MEDIA SERVICE; (v) YOUR RELIANCE ON CONTENT
                  MADE AVAILABLE BY US; OR (vi) ANY OTHER MATTER RELATING TO THE
                  PROFUSION MEDIA SERVICE. SOME JURISDICTIONS DO NOT ALLOW THE
                  EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION
                  OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                  ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH
                  MAY NOT APPLY TO YOU.
                </p>
                <p className="text-uppercase">
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, OUR MAXIMUM
                  LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE PPROFUSION
                  MEDIA SERVICE OR YOUR USE OF PROFUSION MEDIA CONTENT,
                  REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT,
                  BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED £100.
                </p>
                <p>
                  <span className="fw-bold">Indemnification.</span>
                  &nbsp;You agree to defend, indemnify, and hold harmless the
                  Released Parties from and against any and all claims,
                  liabilities, damages, losses, costs, expenses, fees, actions,
                  or demands, including, without limitation, reasonable legal
                  and accounting fees, alleging or resulting from (i) your use
                  of or reliance on any third-party content, (ii) your use of or
                  reliance on any Profusion Media Content, (iii) any of Your
                  User Content, (iv) your violation of any person’s intellectual
                  property, privacy, publicity or other right, (v) the violation
                  of any applicable laws and/or these Terms by you and/or anyone
                  using your log-in credentials to access and otherwise use the
                  Profusion Media Service (in whole or in part), (vi) the breach
                  of any of your warranties, representations, responsibilities
                  or other obligations set forth in these Terms, or (vii) the
                  wilful misconduct of you or anyone accessing the Profusion
                  Media Service using your log-in credentials. We shall provide
                  notice to you promptly of any such claim, suit, or proceeding.
                  We reserve the right to assume the exclusive defence and
                  control of any matter otherwise subject to indemnification by
                  you, in which event you will cooperate with us in asserting
                  any available defences. This provision shall remain in full
                  force and effect notwithstanding any termination of your use
                  of the Profusion Media Service.
                </p>
                <h4 className="text-uppercase">XIV. General Terms</h4>
                <p>
                  These Terms constitute the entire agreement between you and
                  Profusion Media concerning your use of the Profusion Media
                  Service. Our failure to exercise or enforce any right or
                  provision of these Terms shall not constitute a waiver of such
                  right or provision. If any provision of these Terms is found
                  by a court of competent jurisdiction to be invalid, the
                  parties nevertheless agree that the court should endeavour to
                  give effect to the parties’ intentions as reflected in the
                  provision, and the other provisions of these Terms remain in
                  full force and effect. The section titles and annotations in
                  these Terms are for convenience only and have no legal or
                  contractual effect.
                </p>
                <h4 className="text-uppercase">
                  XV. Arbitration, Class Waiver, and Waiver of Jury Trial
                </h4>
                <p>
                  We are located in London, United Kingdom, so all disputes must
                  be resolved there. We will use arbitration to resolve any
                  problems, and you cannot join a class action lawsuit or obtain
                  a jury trial for any disputes you have with us related to your
                  use of the Profusion Media Service.
                </p>
                <p>
                  These Terms and the relationship between you and Profusion
                  Media shall be governed by the laws of England without regard
                  to its conflict of law provisions, regardless of where you
                  live. You and Profusion Media agree to submit any disputes
                  relating to your use of the Profusion Media Service for final
                  and binding arbitration. Any such arbitration, to the extent
                  necessary, shall be conducted in London, England. You covenant
                  not to sue Profusion Media in any other forum.
                </p>
                <p>
                  You also acknowledge and understand that, with respect to any
                  dispute with the Released Parties arising out of or relating
                  to your use of the Profusion Media Service or these Terms:
                </p>
                <p className="text-uppercase">
                  YOU ARE GIVING UP YOUR RIGHT TO HAVE A TRIAL BY JURY;
                </p>
                <p className="text-uppercase">
                  YOU ARE GIVING UP YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS
                  A PRIVATE LAWYER, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR
                  TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY
                  LAWSUIT INVOLVING ANY SUCH DISPUTE; AND
                </p>
                <p className="text-uppercase">
                  YOU MUST FILE ANY CLAIM WITHIN ONE (1) YEAR AFTER SUCH CLAIM
                  AROSE OR IT IS FOREVER BARRED.
                </p>
                <p>Contact Information</p>
                <address>
                  Profusion Media Ltd
                  <br />
                  Telephone House, Ground floor
                  <br />
                  69-77 Paul Street
                  <br />
                  EC2A 4NW
                  <br />
                  <a href="mailto: legal@profusion.com">legal@profusion.com</a>
                </address>
                <p>
                  <span className="text-uppercase">EFFECTIVE DATE:</span>
                  &nbsp;1st December 2021
                </p>
                <p>
                  <span className="text-uppercase">LAST UPDATED:</span>
                  &nbsp;Due 31st November 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyleModal>
    </>
  );
}

export default TermsModal;
