import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/Main';
import Hero from '../../components/Hero';
import BackgroundImage from '../../assets/images/hero/background.png';
import DisplayImage from '../../assets/images/hero/partner-logo.svg';

const Error = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainLayout>
        <Hero
          background={BackgroundImage}
          mainImage={DisplayImage}
          title="Profusion Data Academy"
          subTitle="Sisense Training Courses"
          text="We offer training courses so you can maximise your skills in your preferred location and timezone."
        />
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col-12 col-lg-8 offset-lg-2 text-center">
              <h2 className="text-center fw-bold mb-3">Something went wrong</h2>
              <p className="text-center mb-4">
                Please return back to the homepage
              </p>
              <Link to="/">
                <button type="button" className="btn btn-primary mt-5">
                  Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Error;
