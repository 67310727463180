import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Loader from '../components/Loader';

const Main = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { loading } = useSelector(
    (_S) => ({
      loading: _S.CoursesReducer.loading,
    }),
    shallowEqual,
  );

  return (
    <>
      {loading && (
      <Loader />
      )}
      <Navigation />
      <div id="wrapper">
        <main className="wrapper px-md-0 pb-lg-5">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Main.defaultProps = {
  children: null,
};

export default Main;
