/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../layouts/Main';
import Hero from '../../components/Hero';
import BackgroundImage from '../../assets/images/hero/background.png';
import DisplayImage from '../../assets/images/hero/partner-logo.svg';
import CourseItem from '../../components/CourseItem';
import { CoursesActionCreators } from '../../redux/actions/courses';
import { compareDates } from '../../helpers/date-time-formatter';

const Home = () => {
  const dispatch = useDispatch();
  const { getCourses, resetState } = bindActionCreators(CoursesActionCreators, dispatch);
  useEffect(() => {
    window.scrollTo(0, 0);
    resetState();
    getCourses();
  }, []);

  const { courses } = useSelector(
    (_S) => ({
      courses: _S.CoursesReducer.courses,
    }),
    shallowEqual,
  );

  return (
    <>
      <MainLayout>
        <Hero
          background={BackgroundImage}
          mainImage={DisplayImage}
          title="Profusion Data Academy"
          subTitle="Sisense Training Courses"
          text="We offer training courses so you can maximise your skills in your preferred location and timezone."
        />
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 py-5">
              <div className="d-flex justify-content-center py-lg-5">
                <p className="text-center mb-lg-0">
                  We all know that data has quickly become a critical part of
                  the business world. However, many companies struggle to use
                  the information they have effectively. This can put them at a
                  serious disadvantage – impacting their ability to make
                  informed business decisions and better serve their customers.
                  But it doesn’t have to be this way.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <h2 className="text-center fw-bold">Available Courses</h2>
              {courses.length > 0
              && courses.map((item, index) => {
                const expired = compareDates(new Date(), item.registerBy);
                return (
                  <CourseItem
                    key={index}
                    course={item}
                    expired={expired}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Home;
