/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CoursesActionCreators } from '../../redux/actions/courses';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../HeroLandscape';
import Timezones from '../Agenda/timezones';

const SummaryForm = ({ course }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { userDetails, promoStatus } = useSelector(
    (_S) => ({
      userDetails: _S.CoursesReducer.userDetails,
      promoStatus: _S.CoursesReducer.promoStatus,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const { resetState, updateStep } = bindActionCreators(CoursesActionCreators, dispatch);
  const { postBooking } = CoursesActionCreators;
  const submitBooking = async () => {
    try {
      const response = await postBooking(course, userDetails);
      if (response.requiresAction === false) {
        navigate('/success');
      }
    } catch (e) {
      resetState();
      navigate('/failure');
    }
  };

  const prevStep = async () => {
    updateStep(1);
  };

  return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="fw-bold">Registration Summary</h2>
              <p className="my-3">
                Take a moment to review your registration before continuing.
              </p>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12 summary">
              {course.length > 0
              && course.map((item) => {
                return (
                  <div key={item.id}>
                    <div className="row">
                      <div className="col-12">
                        <p className="fw-bold mb-0">Course</p>
                        <p>{item.name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <p className="fw-bold mb-0">Date</p>
                        <p>
                          {new Date(item.startDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              month: 'short',
                            },
                          )}
                          {' '}
                          {new Date(item.startDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              day: '2-digit',
                            },
                          )}
                          -
                          {new Date(item.endDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              day: '2-digit',
                            },
                          )}
                          {', '}
                          {new Date(item.endDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              year: 'numeric',
                            },
                          )}
                        </p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="fw-bold mb-0">Time</p>
                        <Timezones content={item} />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                {userDetails
              && (
              <>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">First Name</p>
                    <p>{userDetails.firstName ? userDetails.firstName : ''}</p>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Last Name</p>
                    <p>{userDetails.lastName ? userDetails.lastName : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Email</p>
                    <p>{userDetails.email ? userDetails.email : ''}</p>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Company</p>
                    <p>{userDetails.company ? userDetails.company : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Job Title</p>
                    <p>{userDetails.jobTitle ? userDetails.jobTitle : ''}</p>
                  </div>
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Mobile</p>
                    <p>{userDetails.mobile ? userDetails.mobile : ''}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <p className="fw-bold mb-0">Manager</p>
                    <p>{userDetails.customerSuccessManager ? userDetails.customerSuccessManager : ''}</p>
                  </div>
                  <div className="col-12 col-lg-6">
                    {userDetails.promoCode && (
                    <>
                      <p className="fw-bold mb-0">Promo Code</p>
                      <p>{userDetails.promoCode ? userDetails.promoCode : ''}</p>
                    </>
                    )}
                  </div>
                </div>
              </>
              )}
              </div>
            </div>
            <div className="col-12 d-lg-flex justify-content-center mt-5">
              <button type="button" className="btn btn-outline-dark me-4 mb-3 mb-md-0" onClick={() => prevStep()}>
                go back
              </button>
              <Link to="/">
                <button
                  type="button"
                  className="btn btn-outline-dark me-4 mb-3 mb-md-0"
                >
                  Cancel
                </button>
              </Link>
              {(function () {
                if (promoStatus && promoStatus !== null && promoStatus.discount === 1) {
                  return (
                    <button type="button" className="btn btn-primary me-4 mb-3 mb-md-0" onClick={submitBooking}>
                      Submit booking
                    </button>
                  );
                }
                return (
                  <Link to={`/payment/${course[0].id}`}>
                    <button type="button" className="btn btn-primary me-4 mb-3 mb-md-0">
                      proceed to payment
                    </button>
                  </Link>
                );
              }())}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default SummaryForm;
