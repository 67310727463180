import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/common/logo.svg';

const Navigation = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid mx-5 my-3">
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              alt="Profusion.com"
              className="d-inline-block align-middle mr-2"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="navbar-nav ms-auto">
              <a
                href="https://profusion.com"
                className="text-end text-decoration-none mx-3 backBtn"
                rel="noreferrer"
              >
                <button className="btn btn-primary px-3 py-2" type="button">
                  Back to Profusion
                </button>
              </a>
              <a
                href="https://profusion.com/contact-us/"
                target="_blank"
                className="text-white text-end text-decoration-none"
                rel="noreferrer"
              >
                <button className="btn btn-primary px-3 py-2" type="button">
                  Contact us
                </button>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
