import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/common/logo.svg';
import youtube from '../../assets/images/footer/youtube.png';
import twitter from '../../assets/images/footer/twitter.png';
import linkedin from '../../assets/images/footer/linkedin.png';

const Footer = () => {
  return (
    <>
      <footer className="bg-dark py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Link to="/">
                <img
                  src={logo}
                  alt="Profusion.com"
                  className="d-inline-block align-middle"
                />
              </Link>
            </div>
          </div>
          <div className="row pt-4 border-bottom">
            <div className="col-6">
              <p className="text-white">Follow us</p>
            </div>
            <div className="col-6 text-white text-end ">
              <a href="https://profusion.com/contact-us/" target="_blank" className="text-white text-end text-decoration-none" rel="noreferrer">Contact us</a>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-12 col-lg-6 mb-3">
              <a href="https://www.linkedin.com/company/profusion/" target="_blank" rel="noreferrer">
                <img
                  src={linkedin}
                  alt="Profusion Linkedin"
                  className="d-inline-block align-middle me-3"
                />
              </a>
              <a href="https://twitter.com/prfsn" target="_blank" rel="noreferrer">
                <img
                  src={twitter}
                  alt="Profusion Twitter"
                  className="d-inline-block align-middle me-3"
                />
              </a>
              <a href="https://www.youtube.com/channel/UCoc4W0ysg2cUdhF2fegbXjw" target="_blank" rel="noreferrer">
                <img
                  src={youtube}
                  alt="Profusion Youtube"
                  className="d-inline-block align-middle"
                />
              </a>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <p className="text-white text-lg-end">
                &copy;
                {' '}
                {new Date().getFullYear()}
                {' '}
                Profusion, All rights reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
