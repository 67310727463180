export const CoursesActionTypes = {
  RESET_STATE: 'RESET_STATE',
  GET_COURSES_REQ: 'GET_COURSES_REQ',
  GET_COURSES_RES: 'GET_COURSES_RES',
  GET_COURSE_REQ: 'GET_COURSE_REQ',
  GET_COURSE_RES: 'GET_COURSE_RES',
  GET_REGISTRATION_REQ: 'GET_REGISTRATION_REQ',
  GET_REGISTRATION_RES: 'GET_REGISTRATION_RES',
  UPDATE_STEP: 'UPDATE_STEP',
};
