/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RegisterForm from '../../components/Registration/registerForm';
import SummaryForm from '../../components/Registration/summaryForm';
import { CoursesActionCreators } from '../../redux/actions/courses';

const Registration = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { getCourse } = bindActionCreators(CoursesActionCreators, dispatch);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCourse(id);
  }, []);

  const { course, coursesErr, step } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
      coursesErr: _S.CoursesReducer.coursesErr,
      step: _S.CoursesReducer.step,
    }),
    shallowEqual,
  );

  switch (step) {
    default:
      return <h1>Something went wrong, please try again</h1>;
    case 1:
      return <RegisterForm course={course} />;
    case 2:
      return <SummaryForm course={course} />;
  }

  /* return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        {step === 1 && <RegisterForm nextStep={nextStep} />}
        {step === 2 && course && <SummaryForm prevStep={prevStep} course={course} />}
      </MainLayout>
    </>
  ); */
};

export default Registration;
