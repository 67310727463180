/* eslint-disable no-unused-vars */
import { CoursesActionTypes } from '../../types';

import { api } from '../../../helpers/api';

export const CoursesActionCreators = {
  resetState: () => async (dispatch) => {
    dispatch({
      type: CoursesActionTypes.RESET_STATE,
    });
  },
  getCourses: () => async (dispatch) => {
    dispatch({
      type: CoursesActionTypes.GET_COURSES_REQ,
    });
    try {
      const response = await api('GET', 'courses');
      dispatch({
        type: CoursesActionTypes.GET_COURSES_RES,
        error: null,
        payload: response,
      });
    } catch (e) {
      dispatch({
        type: CoursesActionTypes.GET_COURSES_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  getCourse: (id) => async (dispatch) => {
    dispatch({
      type: CoursesActionTypes.GET_COURSE_REQ,
    });
    try {
      const course = await api('GET', `courses/${id}`);
      const modules = await api('GET', `courses/${id}/modules`);
      dispatch({
        type: CoursesActionTypes.GET_COURSE_RES,
        error: null,
        payload: { course, modules },
      });
    } catch (e) {
      dispatch({
        type: CoursesActionTypes.GET_COURSE_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  setUserDetails: (values, id) => async (dispatch) => {
    dispatch({
      type: CoursesActionTypes.GET_REGISTRATION_REQ,
    });
    try {
      if (values.promoCode) {
        const promoCodeStatus = await api('GET', `checkPromoCode/${values.promoCode}`);
        dispatch({
          type: CoursesActionTypes.GET_REGISTRATION_RES,
          error: null,
          payload: { id, values, promoCodeStatus },
        });
      } else {
        dispatch({
          type: CoursesActionTypes.GET_REGISTRATION_RES,
          error: null,
          payload: { id, values },
        });
      }
    } catch (e) {
      dispatch({
        type: CoursesActionTypes.GET_REGISTRATION_RES,
        error: e?.data?.message || e.message || 'Something went wrong',
      });
    }
  },
  postBooking: async (course, userDetails, paymentMethodId = null) => {
    const response = await api('POST', `courses/${course[0].id}/book`, {
      ...userDetails,
      paymentMethodId,
    });
    return response;
  },
  confirmPayment: async (paymentIntentId) => {
    const response = await api('POST', 'confirmPayment', {
      paymentIntentId,
    });
    return response;
  },
  updateStep: (step) => async (dispatch) => {
    dispatch({
      type: CoursesActionTypes.UPDATE_STEP,
      payload: { step },
    });
  },
};
