/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TimeWhiteImage from '../../assets/images/courses/time-white.png';
import { formatedDateRange, formatDate } from '../../helpers/date-time-formatter';
import Timezones from '../Agenda/timezones';

const JumbotronDiv = styled.section`
  background-image: url(${(props) => props.background});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -110px;
  padding-top: 150px;
  padding-bottom: 110px;
  .timezones {
    background: rgba(241, 239, 248, 0.3);
    color: #FFF;
  }
  .timezones span {
    color: #FFF;
  }
  @media (max-width: 1200px) {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.7),  rgba(0,0,0,0.7)), url(${(props) => props.background});
  }
`;

function HeroLandscape(props) {
  const {
    course,
  } = props;
  const date = `${formatedDateRange(course)}, ${formatDate(course.startDate, { year: 'numeric' })}`;
  return (
    <JumbotronDiv background={course.imageHero}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            { course.name && <h1 className="text-white text-center font-weight-bolder mb-4">{course.name}</h1> }
            { date && (
            <p className="text-white text-center font-weight-light mb-4">
              {' '}
              <img src={TimeWhiteImage} className="me-2" alt="Time" />
              {date}
            </p>
            ) }
            <div className="text-white text-center font-weight-light mb-4"><Timezones content={course} /></div>
          </div>
        </div>
      </div>
    </JumbotronDiv>
  );
}

export default HeroLandscape;
