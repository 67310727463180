/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '20px',
          color: '#888888',
          letterSpacing: '0.025em',
          fontFamily: 'Arial, Helvetica, sans-serif',
          '::placeholder': {
            color: '#888888',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const PaymentForm = ({ setStripeId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      console.log('[error]', error);
    } else {
      setStripeId(paymentMethod.id);
    }
  };

  const options = useOptions();
  return (
    <>
      <form className="s-form" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <div className="form-group">
                <label htmlFor="card-element">Card Number</label>
                <CardNumberElement
                  options={options}
                  id="card-element"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <div className="form-group">
                <label htmlFor="expiry-element">Expiration</label>
                <CardExpiryElement
                  options={options}
                  id="expiry-element"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <div className="form-group">
                <label htmlFor="cvc-element">CVC</label>
                <CardCvcElement
                  options={options}
                  id="cvc-element"
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-5">
            <Link to="/">
              <button
                type="button"
                className="btn btn-outline-dark me-4 mb-3 mb-md-0"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              className="btn btn-primary me-4 mb-3 mb-md-0"
              disabled={!stripe}
            >
              Confirm payment
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentForm;
