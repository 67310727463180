/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import MainLayout from '../../layouts/Main';
import Hero from '../../components/Hero';
import IconOne from '../../assets/images/course-details/date.png';
import IconTwo from '../../assets/images/course-details/time.png';
import IconThree from '../../assets/images/course-details/lectures.png';
import IconFour from '../../assets/images/course-details/price.png';
import IconFive from '../../assets/images/course-details/icon2.png';
import IconSeven from '../../assets/images/course-details/icon1.png';
import Divider from '../../assets/images/courses/divider.png';
import AgendaList from '../../components/Agenda';
import { CoursesActionCreators } from '../../redux/actions/courses';
import { getStandardDate, compareDates, formatedDateRange } from '../../helpers/date-time-formatter';

const StyledDiv = styled.div`
  margin-top: -55px;
  background: #fff;
  height: auto;
  padding: 1rem;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  z-index: 10;
  p {
    margin-bottom: 0;
  }
  @media (max-width: 992px) {
    padding: 1rem;
  }
`;

const StyledBox = styled.div`
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  height: 100%;
  padding: 3rem 0;
`;

const CourseDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { getCourse } = bindActionCreators(CoursesActionCreators, dispatch);
  useEffect(() => {
    window.scrollTo(0, 0);
    getCourse(id);
  }, []);

  const { course, modules } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
      modules: _S.CoursesReducer.modules,
    }),
    shallowEqual,
  );

  const modulesCount = modules.filter((module) => module.type === 'session').length;

  return (
    <>
      <MainLayout>
        {course.length > 0
              && course.map((item) => {
                const expired = compareDates(new Date(), item.registerBy);
                return (
                  <div key={item.id}>
                    <Hero
                      bookBtn={`/registration/${item.id}`}
                      background={item.imageHero}
                      mainImage={null}
                      title={item.name}
                      text={item.descriptionHero}
                      expired={expired}
                    />
                    <div className="container">
                      <div className="row">
                        <StyledDiv className="col-12 col-lg-10 offset-lg-1">
                          <div className="row h-100 d-flex align-items justify-content-center px-4">
                            <div className="col-6 col-lg-3">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-12 d-flex flex-row">
                                  <div className="me-2">
                                    <img src={IconOne} alt="Date" />
                                  </div>
                                  <div>
                                    <p>
                                      <strong>DATE</strong>
                                    </p>
                                    <p>
                                      {formatedDateRange(item)}
                                      {', '}
                                      {new Date(item.endDate).toLocaleDateString(window.navigator.language, {
                                        year: 'numeric',
                                      })}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-lg-3 pb-4 pb-lg-0">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-12 d-flex flex-row">
                                  <div className="me-2">
                                    <img src={IconTwo} alt="Time" />
                                  </div>
                                  <div>
                                    <p>
                                      <strong>DURATION</strong>
                                    </p>
                                    <p>{item.duration}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-lg-3">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-12 d-flex flex-row">
                                  <div className="me-2">
                                    <img
                                      src={IconThree}
                                      alt="Lectures"
                                    />
                                  </div>
                                  <div className="col-9">
                                    <p>
                                      <strong>LECTURES</strong>
                                    </p>
                                    <p>{`${modulesCount} sessions`}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-lg-3">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-12 d-flex flex-row">
                                  <div className="me-2">
                                    <img src={IconFour} alt="Price" />
                                  </div>
                                  <div className="col-9">
                                    <p>
                                      <strong>PRICE</strong>
                                    </p>
                                    <p>{`£${item.price}`}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </StyledDiv>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2 py-4 py-md-5">
                          <div className="text-center">
                            <div dangerouslySetInnerHTML={{ __html: item.description }} />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-12 col-lg-4">
                          <StyledBox>
                            <div className="d-flex justify-content-center align-items-center flex-column px-4">
                              <img
                                src={IconFive}
                                alt="certificates"
                                className="img-fluid"
                              />
                              <h4 className="mt-4 fw-bold text-center">Certificates</h4>
                              <img src={Divider} alt="Divider" className="my-3" />
                              <div className="text-center" dangerouslySetInnerHTML={{ __html: item.certificates }} />
                            </div>
                          </StyledBox>
                        </div>
                        <div className="col-12 col-lg-4">
                          <StyledBox>
                            <div className="d-flex justify-content-center align-items-center flex-column px-4">
                              <img
                                src={IconSeven}
                                alt="prerequisites"
                                className="img-fluid"
                              />
                              <h4 className="mt-4 fw-bold text-center">
                                Audience Prerequisites
                              </h4>
                              <img src={Divider} alt="Divider" className="my-3" />
                              <div className="text-center" dangerouslySetInnerHTML={{ __html: item.prerequisites }} />
                            </div>
                          </StyledBox>
                        </div>
                        <div className="col-12 col-lg-4">
                          <StyledBox>
                            <div className="d-flex justify-content-center align-items-center flex-column px-4">
                              <img
                                src={IconSeven}
                                alt="requirements"
                                className="img-fluid"
                              />
                              <h4 className="mt-4 fw-bold text-center">
                                Technical Requirements
                              </h4>
                              <img src={Divider} alt="Divider" className="my-3" />
                              <div className="text-center" dangerouslySetInnerHTML={{ __html: item.requirements }} />
                            </div>
                          </StyledBox>
                        </div>
                      </div>
                      <div className="row py-5">
                        <div className="col-12">
                          <h2 className="fw-bold">Agenda</h2>
                          <p>Here’s what’s scheduled for the event:</p>
                        </div>
                      </div>
                      <div className="row pb-5 mb-5">
                        <AgendaList modules={modules} />
                      </div>
                      <div className="row my-5">
                        <div className="col-10 offset-1 text-center p-5 bg-dark">
                          <h3 className="text-white">Limited Seats Available, Reserve your Seat Today!</h3>
                          <h4 className="text-white">
                            Price:
                            {' '}
                            {`£${item.price}`}
                          </h4>
                          <p className="text-white">
                            Register by:
                            &nbsp;
                            {getStandardDate(item.registerBy)}
                          </p>
                          {!expired && (
                          <>
                            <Link to={`/registration/${item.id}`}><button type="button" className="btn btn-primary me-sm-4 mb-3">BOOK</button></Link>
                            <a
                              href="https://profusion.com/contact-us/"
                              target="_blank"
                              className="text-white text-end text-decoration-none"
                              rel="noreferrer"
                            >
                              <button className="btn btn-outline-light mb-3" type="button">
                                Contact us
                              </button>
                            </a>
                          </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
      </MainLayout>
    </>
  );
};

export default CourseDetails;
