import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../../components/HeroLandscape';
import UnsuccessfulImage from '../../assets/images/general/unsuccessful.png';

const PaymentFailure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { course } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
    }),
    shallowEqual,
  );

  return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col-12 col-lg-8 offset-lg-2 text-center">
              <img
                src={UnsuccessfulImage}
                alt="Unsuccessful"
                className="img-fluid mb-4"
              />
              <h2 className="text-center fw-bold mb-3">Payment unsuccessful</h2>
              <p className="text-center mb-4">
                Unfortunately your payment didn&apos;t go through on this
                occassion. However, this can be for a lot of different reasons.
                Please return to the course page and try again.
              </p>
              {course.length > 0
                && course.map((item) => {
                  return (
                    <>
                      <Link to={`/payment/${item.id}`}>
                        <button type="button" className="btn btn-primary mt-5">
                          Try again
                        </button>
                      </Link>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default PaymentFailure;
