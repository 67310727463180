import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './reducers/index';
import { saveState, loadState } from '../helpers/localStorage';

const composeEnhancers = typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const persistedStore = loadState();

const store = createStore(Reducers, persistedStore, enhancer);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
