/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModuleDetails from './module-details';
import { formatDate, getStandardDate } from '../../helpers/date-time-formatter';

const StyledDiv = styled.div`
  border-top: 3px solid #2500b6;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  cursor: pointer;
`;

function AgendaList(props) {
  const { modules } = props;
  const [content, setContent] = useState(modules[0]);

  return (
    <>
      <div className="col-12 col-lg-3">
        <div className="row">
          {modules.length > 0
        && modules.map((item) => {
          return (
            <StyledDiv
              key={item.id}
              className="col-12 p-4 mb-3"
              onClick={() => setContent(item)}
            >
              <div className="row">
                <div className="col-12">
                  <h3 className="fw-bold fs-5">{item.name}</h3>

                  {/* hidden below 992 */}
                  {item.type === 'session' && (
                  <div className="d-none d-lg-block">
                    <p className="mt-3 mb-0">
                      {formatDate(item.startTime, { weekday: 'long' })}
                      {' '}
                      {getStandardDate(item.startTime)}
                    </p>
                  </div>
                  )}

                  {/* display below 992 */}
                  <div className="d-block d-lg-none">
                    <ModuleDetails content={item} />
                  </div>
                </div>
              </div>
            </StyledDiv>
          );
        })}
        </div>
      </div>
      {/* hidden below 992 */}
      <div className="col-12 col-lg-8 offset-1 d-none d-lg-block" key="desc-block-0">
        <h2 className="m-0 pb-3 fw-bold">{content.name}</h2>
        <ModuleDetails content={content} />
      </div>
    </>
  );
}

AgendaList.propTypes = {
  modules: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

AgendaList.defaultProps = {
  modules: null,
};

export default AgendaList;
