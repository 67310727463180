import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './assets/css/global.css';
import './assets/css/media.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { history } from './utils/history';
import Home from './pages/Home';
import CourseDetails from './pages/CourseDetails';
import Registration from './pages/Registration';
import Payment from './pages/Payment';
import PaymentConfirmation from './pages/Payment/paymentConfirmation';
import PaymentSuccess from './pages/Payment/paymentSuccess';
import PaymentFailure from './pages/Payment/paymentFailure';
import Error from './pages/Error';

function App() {
  return (
    <Router history={history}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/details/:id" element={<CourseDetails />} />
        <Route exact path="/registration/:id" element={<Registration />} />
        <Route exact path="/payment/:id" element={<Payment />} />
        <Route exact path="/confirmPayment" element={<PaymentConfirmation />} />
        <Route exact path="/success" element={<PaymentSuccess />} />
        <Route exact path="/failure" element={<PaymentFailure />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
