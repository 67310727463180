/* eslint-disable react/prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TimeImage from '../../assets/images/courses/time.png';
import Divider from '../../assets/images/courses/divider.png';
import { formatedDateRange } from '../../helpers/date-time-formatter';
import PriceIcon from '../../assets/images/course-details/price.png';
import Timezones from '../Agenda/timezones';

function CourseItem({ course, expired }) {
  return (
    <div className="container mb-3">
      <div className="row justify-content-center py-5">
        <div className="col-12 col-lg-4">
          {course.imageThumbnail && (
            <img
              src={course.imageThumbnail}
              className="img-fluid"
              alt="Profusion Sisense Partnership"
            />
          )}
        </div>
        <div className="col-12 col-lg-8 px-lg-5">
          <div className="row">
            {course.name && (
              <div className="col-12">
                <h3 className="fw-bold">
                  {course.name}
                  {' '}
                  {course.region ? `(${course.region})` : ''}
                </h3>
              </div>
            )}
            {course.startDate && course.endDate && (
              <div className="col-12">
                <p>
                  <img src={TimeImage} className="me-2" alt="Time" />
                  {formatedDateRange(course)}
                </p>
              </div>
            )}
            {course.startDate && course.endDate && (
              <div className="col-12 mb-3"><Timezones content={course} /></div>
            )}
            {course.price && (
              <div className="col-12">
                <p className="mb-0">
                  <img src={PriceIcon} alt="Price" />
                  {' '}
                  {`£${course.price}`}
                </p>
              </div>
            )}
            <div className="col-2 my-3">
              <img src={Divider} alt="Divider" />
            </div>
            {course.description && (
              <div className="col-12">
                <p dangerouslySetInnerHTML={{ __html: course.description }} />
              </div>
            )}
            <div className="col-12">
              <Link to={`/details/${course.id}`}>
                <button type="button" className="btn btn-outline-dark me-4 mb-3">
                  LEARN MORE
                </button>
              </Link>
              {course.placesRemaining && !expired > 0 ? (
                <Link to={`/registration/${course.id}`}>
                  <button type="button" className="btn btn-primary mb-3">
                    BOOK (
                    {`${course.placesRemaining}`}
                    {' '}
                    places remaining)
                  </button>
                </Link>
              ) : (
                <button type="button" className="btn btn-primary mb-3" disabled>
                  Sold Out
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CourseItem.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    imageThumbnail: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    times: PropTypes.string,
    description: PropTypes.string,
    placesRemaining: PropTypes.number,
    region: PropTypes.string,
  }),

};

CourseItem.defaultProps = {
  course: null,
};

export default CourseItem;
