/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../../components/HeroLandscape';
import PaymentForm from '../../components/PaymentForm';
import { CoursesActionCreators } from '../../redux/actions/courses';
import TimeImage from '../../assets/images/courses/time.png';
import Loader from '../../components/Loader';

const StyledDiv = styled.div`
  border-top: 3px solid #2500b6;
  background: #f5f3fc;
`;

const Payment = () => {
  const stripe = loadStripe(
    'pk_live_51JfoOh2n7C58a12vfnFeS0JY9WuzP8Z1q868Dd3oBA1MPgKQC7VSNH21YPPQPgWDHSyF4lqGD18h9qTnSC2Va3OD00ACdjZDID',
  );
  const [stripeId, setStripeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { promoStatus, userDetails, course } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
      userDetails: _S.CoursesReducer.userDetails,
      promoStatus: _S.CoursesReducer.promoStatus,
    }),
    shallowEqual,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { postBooking } = CoursesActionCreators;
  const navigate = useNavigate();

  const handleBooking = async () => {
    try {
      setLoading(true);
      const response = await postBooking(course, userDetails, stripeId);
      if (!response.requiresAction && response.next_action === null) {
        navigate('/success');
      }
      if (response.requiresAction) {
        window.location.replace(response.next_action.redirect_to_url.url);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      navigate('/failure');
    }
  };

  useEffect(() => {
    if (stripeId) {
      handleBooking();
    }
  }, [stripeId]);

  return (
    <>
      {loading && (
      <Loader />
      )}
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="fw-bold">Payment Details</h2>
              <p className="my-3">
                Take a moment to review your registration before continuing.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="p-5">
                <Elements stripe={stripe}>
                  <PaymentForm setStripeId={setStripeId} />
                </Elements>
              </div>
            </div>
            <div className="col-12 col-xl-5">
              <div className="p-3">
                <StyledDiv className="pb-5">
                  {course.length > 0
                    && course.map((item) => {
                      return (
                        <div key={item.id} className="p-4">
                          <div className="row">
                            <div className="col-12">
                              <h5 className="mb-3">{item.name}</h5>
                            </div>
                            <div className="col-12">
                              <p>
                                <img src={TimeImage} className="me-2" alt="Time" />
                                {new Date(item.startDate).toLocaleDateString(
                                  window.navigator.language,
                                  {
                                    month: 'short',
                                  },
                                )}
                                {' '}
                                {new Date(item.startDate).toLocaleDateString(
                                  window.navigator.language,
                                  {
                                    day: '2-digit',
                                  },
                                )}
                                -
                                {new Date(item.endDate).toLocaleDateString(
                                  window.navigator.language,
                                  {
                                    day: '2-digit',
                                  },
                                )}
                                {', '}
                                {new Date(item.endDate).toLocaleDateString(
                                  window.navigator.language,
                                  {
                                    year: 'numeric',
                                  },
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8">
                              <p>Course Price</p>
                            </div>
                            <div className="col-4 c-blue">
                              £
                              {' '}
                              {item.price}
                            </div>
                          </div>
                          {promoStatus !== null && (
                          <>
                            <div className="row">
                              <div className="col-8">
                                <p>Application Discount</p>
                              </div>
                              <div className="col-4 c-blue">
                                {' - £'}
                                { (item.price / 100)
                                  * (promoStatus.discount * 100) }
                              </div>
                            </div>
                          </>
                          )}
                          <div className="row mt-4">
                            <div className="col-8">
                              <p>Total</p>
                            </div>
                            <div className="col-4 c-blue l-font">
                              {' £ '}
                              {promoStatus !== null && (
                              <>
                                {item.price
                                - (item.price / 100)
                                  * (promoStatus.discount * 100)}
                                {' '}
                              </>
                              )}
                              {promoStatus === null && (
                              <>
                                {item.price}
                              </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </StyledDiv>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Payment;
