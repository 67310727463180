import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const JumbotronDiv = styled.section`
  background-image: url(${(props) => props.background});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -110px;
  padding-top: 140px;
  margin-left: -10px;
  padding-bottom: 50px;
  @media (max-width: 1200px) {
    background-position: right;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.7),  rgba(0,0,0,0.7)), url(${(props) => props.background});
  }
`;

function Hero(props) {
  const {
    bookBtn, background, mainImage, title, subTitle, text, expired,
  } = props;
  return (
    <JumbotronDiv background={background}>
      <div className="container">
        <div className="row justify-content-center py-lg-5">
          <div className="col-12 col-lg-7 p-5">
            { title && <h1 className="text-white fw-bold mb-4">{title}</h1> }
            { subTitle && <h3 className="text-white fw-light mb-4">{subTitle}</h3> }
            { text && <p className="text-white mb-4">{text}</p> }
            { !expired && bookBtn && <Link to={bookBtn}><button type="button" className="btn btn-primary me-4">BOOK</button></Link>}
          </div>
          <div className="col-12 col-lg-5">
            { mainImage && <img src={mainImage} className="img-fluid" alt="Profusion Sisense Partnership" /> }
          </div>
        </div>
      </div>
    </JumbotronDiv>
  );
}

Hero.propTypes = {
  bookBtn: PropTypes.string,
  background: PropTypes.string,
  mainImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
  expired: PropTypes.bool,
};

Hero.defaultProps = {
  bookBtn: null,
  background: null,
  mainImage: null,
  title: null,
  subTitle: null,
  text: null,
  expired: false,
};

export default Hero;
