/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../../components/HeroLandscape';

const PaymentSuccess = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { course, promoStatus } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
      promoStatus: _S.CoursesReducer.promoStatus,
    }),
    shallowEqual,
  );

  return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5 success">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="fw-bold">Confirmed</h2>
              <p className="my-3">
                Congratulations, you are now booked on our course!
              </p>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-12 summary">
              {course.length > 0
              && course.map((item) => {
                return (
                  <div key={item.id}>
                    <div className="row">
                      <div className="col-12">
                        <p className="fw-bold mb-0">Course</p>
                        <p>{item.name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <p className="fw-bold mb-0">Date</p>
                        <p>
                          {new Date(item.startDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              month: 'short',
                            },
                          )}
                          {' '}
                          {new Date(item.startDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              day: '2-digit',
                            },
                          )}
                          -
                          {new Date(item.endDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              day: '2-digit',
                            },
                          )}
                          {', '}
                          {new Date(item.endDate).toLocaleDateString(
                            window.navigator.language,
                            {
                              year: 'numeric',
                            },
                          )}
                        </p>
                      </div>
                      <div className="col-12 col-lg-6">
                        <p className="fw-bold mb-0">Time</p>
                        <p dangerouslySetInnerHTML={{ __html: item.marketing }} />
                      </div>
                      <hr />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="fw-bold mb-0">Amount Paid</p>
                        <p>
                          {' £ '}
                          {promoStatus !== null && (
                          <>
                            {item.price
                                - (item.price / 100)
                                  * (promoStatus.discount * 100)}
                            {' '}
                          </>
                          )}
                          {promoStatus === null && (
                          <>
                            {item.price}
                          </>
                          )}

                        </p>
                      </div>
                      <hr />
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="fw-bold mb-0">Payment Terms</p>
                        <p>There is no refund for the UX Dashboard Design Virtual Course.</p>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <p className="c-blue">* A calendar invitation and payment receipt have been sent your email address.</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12 d-flex justify-content-center mt-5">
                          <Link to="/">
                            <button type="button" className="btn btn-primary me-4">
                              return to courses
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default PaymentSuccess;
