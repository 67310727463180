/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../HeroLandscape';
import TermsModal from '../TermsModal';
import { CoursesActionCreators } from '../../redux/actions/courses';

const registerSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name required')
    .min(1, 'First name must be minimum 1 characters')
    .max(255, 'First name must be max 255 characters'),
  lastName: Yup.string()
    .required('Last name required')
    .min(1, 'Last name must be minimum 1 characters')
    .max(255, 'Last name must be max 255 characters'),
  email: Yup.string().email('Invalid email format').required('Email required'),
  confirmEmail: Yup.string()
    .email('Invalid email format')
    .required('Email required')
    .oneOf([Yup.ref('email'), null], 'Emails must match'),
  jobTitle: Yup.string()
    .required('Job Title required')
    .min(1, 'Job Title must be minimum 1 characters')
    .max(255, 'Job Title must be max 255 characters'),
  company: Yup.string()
    .required('Company required')
    .min(1, 'Company must be minimum 1 characters')
    .max(255, 'Company must be max 255 characters'),
  customerSuccessManager: Yup.string()
    .required('Manager required')
    .min(1, 'Manager must be minimum 1 characters')
    .max(255, 'Manager must be max 255 characters'),
  mobile: Yup.string().max(255, 'Mobile must be max 255 characters'),
  promoCode: Yup.string().max(255, 'Mobile must be max 255 characters'),
  termsConfirmation: Yup.boolean().oneOf([true], 'Please accept the terms'),
});

const RegisterForm = ({ course }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { setUserDetails } = bindActionCreators(
    CoursesActionCreators,
    dispatch,
  );
  const { id } = useParams();

  const { coursesErr } = useSelector(
    (_S) => ({
      coursesErr: _S.CoursesReducer.coursesErr,
    }),
    shallowEqual,
  );

  const handleRegister = (values) => {
    setUserDetails(values, id);
  };

  return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="fw-bold">Registration</h2>
              <p className="my-3">
                This course is only available for Sisense customers.
              </p>
              {coursesErr && (
              <div style={{ color: 'red', marginTop: 10 }}>{coursesErr}</div>
              )}
            </div>
          </div>
          <div className="row my-5">
            <div className="d-flex flex-column justify-content-center align-items-center col-12">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  confirmEmail: '',
                  jobTitle: '',
                  company: '',
                  customerSuccessManager: '',
                  mobile: '',
                  promoCode: '',
                  termsConfirmation: false,
                  consent: false,
                }}
                enableReinitialize
                validationSchema={registerSchema}
                onSubmit={(values) => {
                  handleRegister(values);
                }}
              >
                {({ errors, touched }) => (
                  <>
                    <Form>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center flex-column">
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="firstName" className="form-label">
                                  * First Name
                                </label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  className={
                                  errors.firstName
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="firstName"
                                />
                                {errors.firstName && touched.firstName && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="firstName" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="lastName" className="form-label">
                                  * Last Name
                                </label>
                                <Field
                                  name="lastName"
                                  type="lastName"
                                  className={
                                  errors.lastName
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="lastName"
                                />
                                {errors.lastName && touched.lastName && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="lastName" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                  * Email Address
                                </label>
                                <Field
                                  name="email"
                                  type="text"
                                  className={
                                  errors.email
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="email"
                                />
                                {errors.email && touched.email && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="email" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="confirmEmail"
                                  className="form-label"
                                >
                                  * Confirm Email Address
                                </label>
                                <Field
                                  name="confirmEmail"
                                  type="text"
                                  className={
                                  errors.confirmEmail
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="confirmEmail"
                                />
                                {errors.confirmEmail && touched.confirmEmail && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="confirmEmail" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="jobTitle" className="form-label">
                                  * Job Title
                                </label>
                                <Field
                                  name="jobTitle"
                                  type="text"
                                  className={
                                  errors.jobTitle
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="jobTitle"
                                />
                                {errors.jobTitle && touched.jobTitle && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="jobTitle" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="company" className="form-label">
                                  * Company
                                </label>
                                <Field
                                  name="company"
                                  type="text"
                                  className={
                                  errors.company
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="company"
                                />
                                {errors.company && touched.company && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="company" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="customerSuccessManager"
                                  className="form-label"
                                >
                                  * Customer success manager
                                </label>
                                <Field
                                  name="customerSuccessManager"
                                  type="text"
                                  className={
                                  errors.customerSuccessManager
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="customerSuccessManager"
                                />
                                {errors.customerSuccessManager
                                && touched.customerSuccessManager && (
                                  <div className="error-msg pt-2">
                                    <p>
                                      <ErrorMessage name="customerSuccessManager" />
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="mobile" className="form-label">
                                  Mobile
                                </label>
                                <Field
                                  name="mobile"
                                  type="text"
                                  className={
                                  errors.mobile
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="mobile"
                                />
                                {errors.mobile && touched.mobile && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="mobile" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="promoCode" className="form-label">
                                  Promo Code
                                </label>
                                <Field
                                  name="promoCode"
                                  type="text"
                                  className={
                                  errors.promoCode
                                    ? 'form-control error'
                                    : 'form-control'
                                }
                                  id="promoCode"
                                />
                                {errors.promoCode && touched.promoCode && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="promoCode" />
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="mt-3 mb-3">
                                <div className="form-group form-check d-flex align-items-baseline">
                                  <Field
                                    type="checkbox"
                                    name="termsConfirmation"
                                    className={`form-check-input ${
                                      errors.termsConfirmation
                                    && touched.termsConfirmation
                                        ? ' is-invalid'
                                        : ''
                                    }`}
                                  />
                                  <label
                                    htmlFor="termsConfirmation"
                                    className="form-check-label ms-3"
                                  >
                                    I agree to the
                                    {' '}
                                    <a
                                      href="/#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#termsModal"
                                    >
                                      Terms and Conditions
                                    </a>
                                    <TermsModal />
                                  </label>
                                </div>
                              </div>
                              {errors.termsConfirmation
                              && touched.termsConfirmation && (
                                <div className="error-msg pt-2">
                                  <p>
                                    <ErrorMessage name="termsConfirmation" />
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="mt-3 mb-3">
                                <div className="form-group">
                                  <p className="privacy">
                                    Your personal data (i.e. contact information)
                                    will be used by Profusion to process your
                                    booking and secure your training place.
                                    Profusion has appointed Stripe to manage the
                                    financial transaction and related personal
                                    data processing. Stripe provide full
                                    information on their data handling on their
                                    site. For full details on how Profusion
                                    handles your personal data please see
                                    our&nbsp;
                                    <a
                                      className="privacy"
                                      href="https://profusion.com/privacy/"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      privacy policy.
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-5">
                          <button
                            type="button"
                            className="btn btn-outline-dark me-4"
                            onClick={() => navigate(-1)}
                          >
                            go back
                          </button>
                          <button type="submit" className="btn btn-primary me-4">
                            proceed to summary
                          </button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default RegisterForm;
