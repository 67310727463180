/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import MainLayout from '../../layouts/Main';
import HeroLandscape from '../../components/HeroLandscape';
import { CoursesActionCreators } from '../../redux/actions/courses';

const PaymentConfirmation = () => {
  const location = useLocation();
  const paymentIntentId = new URLSearchParams(location.search).get('payment_intent');
  // const dispatch = useDispatch();

  const { confirmPayment } = CoursesActionCreators;
  const navigate = useNavigate();

  const handleBooking = async () => {
    try {
      await confirmPayment(paymentIntentId);
      navigate('/success');
    } catch (e) {
      navigate('/failure');
    }
  };

  const { course } = useSelector(
    (_S) => ({
      course: _S.CoursesReducer.course,
    }),
    shallowEqual,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    handleBooking();
  }, []);

  return (
    <>
      <MainLayout>
        {course.length > 0
          && course.map((item) => {
            return (
              <>
                <HeroLandscape course={item} />
              </>
            );
          })}
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="py-5 text-center">
                Confirming payment
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default PaymentConfirmation;
