/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react';
import TimeImage from '../../assets/images/courses/time.png';
import { getStandardDate } from '../../helpers/date-time-formatter';
import Timezones from './timezones';

function ModuleDetails(props) {
  const { content } = props;
  const { type } = content;

  return (
    <>
      <span>
        {type === 'session' && (
          <>
            <img src={TimeImage} alt="time" className="img-fluid" />
            &nbsp;
            Session Details:
            &nbsp;
            {getStandardDate(content.startTime)}
          </>
        )}
      </span>
      <hr className="" />
      {type === 'session' && <Timezones content={content} />}
      <p dangerouslySetInnerHTML={{ __html: content.description }} />
    </>
  );
}

export default ModuleDetails;
